.container {
  color: var(--text-color);
  flex: 1 0 0px;
}
.value_text p {
  margin: 0;
}
.container video {
  width: 100%;
  object-fit: cover;
}
.value_text h4 {
  background-color: var(--background-color);
  color: var(--heading-color);
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    padding-top: var(--container-padding);
  }
  .value_text h4 {
    margin-bottom: var(--container-margin-mobile);
  }
}
