.container {
  margin-bottom: var(--container-margin);
}
.heading {
  text-transform: uppercase;
  color: var(--heading-color);
  font-size: var(--lg);
  text-align: center;
}
.boxes_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.divider {
  background-color: var(--divider-color);
  width: 100%;
  height: 1px;
  margin-bottom: var(--container-margin);
}
