.modal_btn {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  padding: var(--container-padding);
  border-radius: 5px;
  cursor: pointer;
}

.modal_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border: 1px solid var(--button-hover-border-color);
}

.modal_wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal);
}

.logo_image {
  max-width: 200px !important;
}

.modal {
  background-color: var(--modal-background-color);
  padding: 20px;
  max-width: 800px;
  border-radius: 5px;
}

.modal_close_btn {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-color1);
  align-self: flex-end;
  font-size: var(--xl2);
  cursor: pointer;
  border-radius: var(--border-radius);
  background-color: transparent;
  float: right;
}

.modal_close_btn:hover {
  opacity: 0.8;
}

.show_modal {
  display: flex;
}

.hide_modal {
  display: none;
}

.heading {
  color: var(--heading-color);
  font-size: var(--xl2);
  font-weight: var(--heading-weight-bold);
  margin: 20px 0;
}

.text p,
.text span {
  color: var(--text-color);
  font-size: var(--sm) !important;
}

.text {
  margin-top: 20px;
}

@media screen and (max-width: 980px) {
  .modal_btn {
    padding: 5px var(--container-padding);
    font-size: var(--sm);
    margin-left: var(--container-padding);
  }
  .modal {
    max-width: 90%;
  }
  .logo_image {
    max-width: 100px !important;
  }
}
