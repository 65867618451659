.progress_bar_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: var(--z-index-modal);
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress_bar_wrapper {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 40% !important;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.progress_bar_wrapper h1 {
  font-size: var(--xl2) !important;
  text-align: center;
  color: var(--text-color-dark) !important;
}
.spinner {
  display: inline-block;
  width: 200px;
  height: 30px;
  margin-top: 10px;
  text-align: center;
}
.spinner > div {
  background-color: #189df7;
  height: 100%;
  width: 16px !important;
  margin: 0 2px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    background-color: #189df7;
  }
  20% {
    -webkit-transform: scaleY(1.2);
    background-color: #189df7;
  }
}
@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
    background-color: #189df7;
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1.2);
    background-color: #189df7;
  }
}
@media only screen and (max-width: 980px) {
  .progress_bar_wrapper {
    width: 70% !important;
  }
  .progress_bar_wrapper h1 {
    font-size: var(--xl) !important;
  }
  .spinner {
    margin-top: 30px;
  }
}
