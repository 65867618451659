.container {
  flex: 1 1 0px;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-all);
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 4px;
  overflow: hidden;
  height: fit-content;
  width: calc(100% - var(--container-margin));
  padding: calc(var(--container-margin)/2);
  gap: calc(var(--container-margin)/2);
  margin: calc(var(--container-margin)/2);
}

.top_part {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 30cqw;
  gap: calc(var(--container-margin)/2);
}
.image_wrapper {
  flex-basis: 40%;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.heading_name_wrapper {
  flex-basis: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  gap: calc(var(--container-margin)/4);
}

.heading_wrapper {
  height: min(8cqw, 50px);
  background-color: var(--heading-background-color);
  color: var(--heading-color);
  font-weight: var(--heading-weight-black);
  padding: var(--container-padding) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: wrap;
  border-radius: 4px;
}

.heading {
  font-size: 3cqw;
}

.name_wrapper {
  color: var(--product-name-color);
  min-height: 8cqw;
  display: flex;
  justify-content: center;
  align-items: center;
}


.middle_part {
  display: flex;
  width: 100%;
  gap: calc(var(--container-margin)/2);
  margin-bottom: calc(var(--container-margin)/2);
}

.description_badges_wrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--container-margin)/2);
  flex-basis: 65%;
  font-size:10px;
}

.description_wrapper li {
  font-size: var(--m) !important;
  padding-left: -10px !important;
}
.description_wrapper ul {
  list-style-position: inside;
  padding-left: 0;
}
.description_wrapper,
.description_wrapper p {
  font-size: min(4cqw, 16px);
}

.name {
  font-size: min(4cqw, 16px);
}


.badges_wrapper {
  display: flex;
  gap: calc(var(--container-margin)/5);
  flex-wrap: wrap;
}
.badge {
  width: 50px;
  height: 50px !important;
  object-fit: contain;
}

.rating_price_wrapper {
  display: flex;
  flex-direction: column;
  gap: calc(var(--container-margin)/2);
  flex-basis: 35%;
}
.rating_wrapper {
  display: flex;
  justify-content: end;
}

.price_wrapper {
  display: flex;
  justify-content: end;
  flex-direction: column;
  font-weight: var(--text-weight-black);
}

.price {
  display: flex;
  justify-content: end;
  color: var(--price-color);
}

.compare_price {
  display: flex;
  justify-content: end;
  color: var(--compare-at-color);
  text-decoration: line-through;
  font-weight: var(--text-weight-black);
}


.bottom_part {
  display: flex;
  flex-direction: column;
  gap: calc(var(--container-margin)/2);
  width: 100%;
}

.select {
  width: 100%;
  height: min(8cqw,40px);
  color: var(--text-color-dark);
  outline: none;
  font-size: min(5cqw,16px);
}

.product_btn {
  width: 100%;
  margin: 0 auto;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  font-weight: var(--text-weight-black);
  font-size: var(--xl);
  padding: var(--container-padding) 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: min(4cqw,16px);
}
.product_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}