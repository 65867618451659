.main {
  padding-top: 10px;
}
.main,
.buy_from_btn_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.products_title {
  font-size: var(--xl);
}
.main afterpay-placement {
  margin: 0 !important;
}

@media screen and (max-width: 980px) {
  .main {
    gap: 15px;
  }
  .products_title,
  .main afterpay-placement {
    padding: 0 var(--container-padding);
  }
  .buy_from_btn_wrapper {
    padding: 0 var(--container-padding);
  }
  .products_title {
    font-size: var(--lg);
  }
}
