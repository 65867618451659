.main {
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}
.image_container {
  width: 80px;
  height: 80px;
}
.image_container img {
  object-fit: cover;
}
.img_name_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}
.quantity_selector {
  padding-right: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.quantity_btn {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  font-size: var(--xl2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantity_btn:hover {
  background-color: #fcfaf6;
}
.quantity_btn:disabled {
  background-color: white;
  opacity: 0.5;
  cursor: default;
}
.quantity_txt {
  font-size: var(--xl2);
}
.quantity_txt,
.product_name {
  font-weight: var(--text-weight-bold);
  font-family: var(--text-font-family);
}
@media screen and (max-width: 980px) {
  .image_container {
    width: 60px;
    height: 60px;
  }
  .product_name,
  .quantity_txt {
    font-size: var(--lg);
  }
  .quantity_btn {
    width: 30px;
    height: 30px;
  }
  .subtract_btn {
    padding-bottom: 5px;
  }
}
