.container {
  background-color: var(--background-color);
  flex: 1 1 0px;
  box-shadow: var(--box-shadow-all);
  border-radius: 15px;
  padding: var(--container-padding) 0;
}
.container p {
  margin: 2px 0;
  font-size: var(--sm);
}
.container img {
  width: 24vw !important;
}
.container h5 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.header_container {
  padding: 0 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.user_name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: var(--sm);
  flex: 1 1 0px;
  color: var(--user-name-color);
}
.user_name p {
  font-weight: var(--text-weight-black);
  text-align: right;
}
.icon_wrapper {
  flex: 0 0 45%;
  white-space: nowrap;
  padding-left: 5px;
}
.verified_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0px;
}
.verified_icon {
  color: var(--verified-color);
}
.verified_container p {
  color: var(--verified-color);
  font-weight: var(--text-weight-black);
  font-size: var(--sm);
  margin: 0;
  margin-left: 10px !important;
}
.img_wrapper {
  margin-top: 10px;
}
.review_body {
  padding: 0 var(--container-padding);
  padding-top: var(--container-padding);
  color: var(--text-color);
}
.review_body h5 {
  margin-bottom: var(--container-margin);
  color: var(--heading-color);
}
@media screen and (max-width: 980px) {
  .header_container {
    padding: 0 var(--container-padding);
  }
  .header_container p {
    margin: 0;
    padding: 0;
  }
  .icon_wrapper {
    font-size: var(--lg);
    flex: 0 0 30%;
  }
  .user_name {
    margin-bottom: 10px;
  }
  .user_name p {
    font-size: var(--xl2);
    margin: 0;
  }
  .review_body {
    padding: 0 var(--container-padding);
  }
}
