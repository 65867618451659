.container {
  background-color: var(--background-color);
}
.text {
  color: var(--text-color);
}
.bullets_wrapper,
.container {
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}

@media screen and (max-width: 980px) {
  .container {
    padding: 0 var(--container-padding);
  }
}
