.product_button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  outline: none;
}
.offer_name {
  background-color: var(--heading-background-color);
  color: var(--heading-color);
  font-weight: var(--heading-weight-black);
  margin: 0;
  width: 100%;
  padding: var(--container-padding);
}
.product_text_wrapper {
  padding: var(--container-padding);
  color: var(--text-color);
}
.product_selected {
  opacity: 1;
  border: 2px solid var(--border-color);
}
.product_unselected {
  opacity: 0.5;
  border: 2px solid var(--default-background-color);
}
@media screen and (max-width: 980px) {
  .product_text_wrapper {
    text-align: left;
  }
  .product_text_wrapper p {
    font-size: var(--md);
  }
  .offer_name {
    font-size: var(--md);
  }
}
