.invalid_field {
  border-color: #d8000c !important;
}
.heading {
  font-size: var(--xl);
}
.form {
  display: flex;
  width: 100%;
  gap: 10px;
}
.input_wrapper {
  border: 1px solid transparent;
  flex: 1 1 0px;
  background-color: var(--background-color-grey);
  height: var(--input-field-height);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: var(--xl2);
  gap: 8px;
  animation: opacity 1s;
}
.input_wrapper:focus-within {
  border-color: #9b4e9a;
}
.input_wrapper input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: var(--xl);
  transition: all 1s;
}
.input_wrapper input:disabled {
  opacity: 0.5;
}
.back_btn {
  background-color: transparent;
  border: none;
  font-size: var(--xl3);
  cursor: pointer;
}
.submit_btn {
  background-color: #333333;
  border: none;
  color: white;
  border-radius: var(--border-radius);
  padding: 0 10px;
  font-size: var(--xl3);
  cursor: pointer;
}
.success_msg {
  width: 100%;
  background-color: var(--background-color-grey);
  height: var(--input-field-height);
  border-radius: 5px;
  font-size: var(--xl);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: opacity 1s;
}
.spinner_icon {
  animation-duration: 0.6s;
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 980px) {
  .heading {
    font-size: var(--lg);
    padding: 0 var(--container-padding);
  }
  .form {
    padding: 0 var(--container-padding);
  }
}
