.container {
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  position: relative;
  z-index: var(--z-index-overlay);
  box-shadow: var(--box-shadow-corner);
  padding: var(--container-padding) 0;
  flex-wrap: wrap;
  gap: var(--container-margin);
}

@media screen and (max-width: 980px) {
  .container {
    flex-direction: column;
    gap: var(--container-margin-mobile);
  }
}
