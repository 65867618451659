.guarantee_wrapper {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  background-color: var(--background-color);
  padding: var(--container-padding) 0;
  margin: 0 auto;
}
[data-page-type='two-column'] .guarantee_wrapper {
  width: 100%;
  justify-content: center;
  border-top: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
}
[data-page-type='two-column'] .guarantee_wrapper .badge_wrapper {
  width: 100px;
  margin: 0;
  padding: 0 var(--container-padding);
}
[data-page-type='two-column'] .guarantee_wrapper .text_wrapper {
  flex: 0 0 60%;
}
.badge_wrapper {
  width: 170px;
  margin-right: var(--container-margin);
}
.text_wrapper {
  flex: 0 0 80%;
}
@media screen and (max-width: 980px) {
  .guarantee_wrapper {
    width: 100%;
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
  }
  .text_wrapper {
    flex: 0 0 70%;
  }
  [data-page-type='two-column'] .guarantee_wrapper .text_wrapper p {
    font-size: var(--sm);
  }
  .badge_wrapper {
    width: 70px;
  }
}
