.container {
  flex: 0 0 20%;
}
.label_duration_wrapper {
  display: flex;
  justify-content: center;
}
.label {
  color: var(--label-color);
  display: flex;
  align-items: center;
}
.duration {
  color: var(--duration-color);
  padding-left: var(--container-padding);
}
.duration span {
  font-size: var(--xl);
  font-weight: var(--text-weight-black);
}
