.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: var(--z-index-overlay);
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: var(--button-color);
  padding: 0;
}
.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}
.embla__button_prev {
  left: 0;
}
.embla__button_next {
  right: 0;
}
.embla__button__svg {
  width: 100%;
  height: 100%;
}
.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 14px;
  height: 14px;
  margin-right: 3px;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.embla__dot:after {
  background-color: var(--button-color);
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: '';
}
.embla__dot__is_selected:after {
  opacity: 1;
}

@media screen and (max-width: 980px) {
  .embla__dot {
    width: 7px;
    height: 7px;
  }
}
