.container {
  background-color: var(--background-color);
  scroll-margin-top: 60px;
}
.top_bottom_padding {
  scroll-margin-top: 0;
}
.sec_wrapper {
  display: grid;
  grid-auto-columns: minmax(0, 2fr);
  grid-auto-flow: column;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  gap: 48px;
  padding-right: 25px;
}
.vertically_centered {
  align-content: center;
  padding-left: 25px;
}
.vertically_centered > div {
  align-self: center;
}
.right_column_container {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}
.sticky_left_column {
  position: sticky;
  top: 5px;
  left: 0;
}
@media only screen and (max-width: 980px) {
  .sec_wrapper {
    display: block;
    padding-right: 0;
  }
  .right_column_container {
    padding-bottom: 20px;
    gap: 15px;
  }
  .vertically_centered {
    padding-left: 0;
  }
  .vertically_centered > div {
    padding-top: 1rem;
  }
}
