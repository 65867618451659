.card {
  border-radius: 15px;
  border: 1px solid var(--border-color2);
  padding: var(--container-padding);
  color: var(--text-color);
  background-color: var(--background-color);
}
.founders_card_title {
  font-size: var(--xl4);
  color: var(--heading-color);
  padding: 0 var(--container-padding);
}
.card p {
  font-size: var(--xl2);
  padding: var(--container-padding);
}
@media screen and (max-width: 980px) {
  .card {
    margin-top: var(--container-margin-mobile);
  }
}
