.btn {
  width: 100%;
  background-color: var(--button-color);
  cursor: pointer;
  white-space: normal;
  color: var(--button-text-color);
  text-align: center;
  border: 1px solid var(--button-border-color);
  padding: 25px 0;
  font-size: var(--lg);
  border-radius: var(--border-radius);
  font-weight: var(--heading-weight-bold);
}
.btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
  opacity: 0.8;
}
.btn:disabled {
  cursor: default;
  color: var(--default-background-color);
  background-color: #9a9a9a;
  border-color: #9a9a9a;
}
.modal {
  position: fixed;
  z-index: var(--z-index-modal);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal_content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 640px !important;
  border-radius: 10px;
}
.modal_content p {
  margin-bottom: var(--container-margin);
}
.modal_close:hover,
.modal_close:focus {
  color: var(--text-color-dark);
  text-decoration: none;
  cursor: pointer;
}
.modal_btn {
  width: 70%;
  margin: 0 auto;
}
.invalid_msg {
  background-color: #fccccc;
  padding: 20px;
  margin: 10px 0;
  text-align: left;
  color: var(--color-negative);
  border-radius: 10px;
  border: 1px solid var(--color-negative);
}
.invalid_msg::before {
  content: '⨂';
  font-style: normal;
  font-weight: var(--heading-weight-bold);
  font-size: var(--xl);
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 0.5em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
}
@media only screen and (max-width: 980px) {
  .btn,
  .invalid_msg {
    font-size: var(--lg);
  }
  .modal_content {
    width: 90% !important;
  }
}
