.container {
  display: flex;
  margin-bottom: 10px;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow-all);
  border-radius: 15px;
}
.heading {
  color: var(--heading-color);
  font-weight: var(--text-weight-black);
}
.user_info {
  flex: 0 0 28%;
  padding: 20px;
  background-color: var(--user-info-background-color);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.review {
  flex: 0 0 72%;
  padding: 20px;
  color: var(--text-color);
}
.review .heading {
  margin: 0;
  margin-bottom: var(--container-margin);
}
.review p {
  margin-top: 5px;
}
.user_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.user_name {
  flex: 0 0 65%;
}
.user_name .heading {
  color: var(--user-name-color);
  font-size: var(--xl);
  margin-bottom: 10px;
}
.verified {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.verified p {
  color: var(--verified-color);
  font-size: var(--md);
  font-weight: var(--text-weight-black);
  margin: 0;
  margin-left: 10px;
}
.stars_icon {
  font-size: var(--xl2);
  margin-bottom: var(--container-margin);
}
.verified_icon {
  color: var(--verified-color);
}
@media screen and (max-width: 980px) {
  .container {
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }
  .container h3 {
    font-size: var(--xl2);
    margin-bottom: var(--container-margin);
  }
  .user_info {
    border-bottom-left-radius: 0;
    border-top-right-radius: 15px;
    flex-direction: row;
  }
  .user_info h5 {
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .user_info p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .user_img {
    flex: 0 0 25%;
  }
  .user_name p {
    font-size: var(--lg);
    padding: 0 !important;
    white-space: nowrap;
  }
  .stars_icon {
    font-size: var(--xl);
    width: 100%;
  }
  .verified_icon {
    font-size: var(--xl2);
  }
  .review {
    padding: 10px 20px;
  }
}
