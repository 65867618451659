.review {
  display: flex;
  flex-direction: row;
  padding: var(--container-padding) 0;
}
.review p {
  margin: 0;
  margin-right: 10px;
}
.name_wrapper {
  display: flex;
  margin-top: 10px;
}
.reviewer_name {
  font-weight: var(--text-weight-black);
}
.verified_container p {
  color: var(--verified-color);
  font-weight: var(--text-weight-black);
  font-size: var(--sm);
  margin: 0;
  margin-left: 10px !important;
}
.verified_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.verified_icon {
  color: var(--verified-color);
}
.review_detail {
  font-weight: var(--text-weight-normal);
  padding-right: 20px;
  padding-top: 10px;
}
.user_img_wrapper {
  flex: 0 0 70px;
  margin-right: 20px;
  align-self: center;
}
@media screen and (max-width: 980px) {
  .review p {
    font-size: var(--sm);
  }
  .verified_container p {
    font-size: var(--xs);
  }
}
