.comparison_chart {
  background-color: var(--background-color);
  border-radius: 15px;
  box-shadow: var(--box-shadow-all);
}
.comparison_chart_row {
  display: flex;
  width: 100%;
}
.comparison_chart_wrapper {
  padding-top: var(--container-padding);
}
.comparison_chart h4 {
  font-weight: var(--heading-weight-black);
  color: var(--heading-color);
  margin: var(--container-margin) 0;
}
.comparison_chart p {
  color: var(--text-color);
  margin: 0;
  font-weight: var(--heading-weight-black);
}
.highlight {
  background-color: var(--highlight-color);
}
.first_row {
  padding-top: var(--container-padding);
}
.row_content,
.x_mark_color,
.check_mark_color {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  flex: 1 1 0px;
}
.x_mark_color {
  color: var(--x-mark-color);
}
.check_mark_color {
  color: var(--check-mark-color);
}
@media screen and (max-width: 980px) {
  .comparison_chart {
    border-radius: 0;
    grid-template-columns: repeat(4, 25%);
  }
  .comparison_chart h4,
  .comparison_chart p {
    font-size: var(--xs);
    padding: 0 5px;
  }
  .icon_wrapper img {
    max-height: auto !important;
    max-width: auto !important;
    min-width: auto !important;
    min-height: auto !important;
    width: 17px !important;
    height: 17px !important;
  }
  .first_row {
    padding-top: var(--container-padding);
  }
  .x_mark_color,
  .check_mark_color {
    font-size: var(--xs);
  }
}
