.container {
  background-color: var(--background-color);
  display: flex;
  gap: var(--container-padding);
  padding: 8px 12px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}
.text {
  text-align: center;
  color: var(--text-color);
}
.text p {
  font-size: var(--md);
}
.icon_wrapper {
  width: 22px;
}
@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
    margin: 0 10px;
  }
  .heading {
    font-size: var(--xl4);
  }
}
