.container {
  background-color: var(--background-color);
}
.stars {
  font-size: var(--lg);
  white-space: nowrap;
}
.star_text p {
  font-size: var(--lg);
  color: var(--text-color);
}
.review_stars_wrapper {
  display: flex;
  gap: var(--container-padding);
  align-items: center;
}

@media screen and (max-width: 980px) {
  .container {
    padding: 0 var(--container-padding);
  }
  .stars,
  .star_text p {
    font-size: var(--sm);
  }
}
