.container {
  padding: var(--container-padding) 0;
}
.comparison_wrapper {
  text-align: center;
  color: var(--text-color);
}
.comparison_wrapper h1 {
  color: var(--heading-color);
  margin-bottom: var(--container-margin);
}
.comparison_wrapper p {
  padding: 0 var(--container-padding);
  margin-bottom: var(--container-margin);
}
.sec_wrapper {
  margin: 0 auto;
  padding: 0 10%;
}
.heading {
  margin: 0;
  font-weight: var(--heading-weight-black);
  text-align: center;
}
@media screen and (max-width: 980px) {
  .sec_wrapper {
    width: 100%;
    padding: 0;
  }
  .sec_wrapper p,
  .heading {
    padding: 0 var(--container-padding);
  }
  .heading {
    font-size: var(--xl4);
  }
}
