.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  flex: 1 0 auto;
  font-size: var(--xl);
}
.selector_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 4 0 auto;
  height: 60px;
  padding: var(--container-padding);
  border-radius: var(--border-radius);
  box-shadow: rgb(0, 0, 0, 0) 0px 0px 0px 0px, rgb(0, 0, 0, 0) 0px 0px 0px 0px,
    rgb(242, 242, 242) 0px 0px 0px 2px;
}
.quantity {
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
}
.quantity_btn {
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.quantity_btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
@media screen and (max-width: 980px) {
  .main {
    padding: 0 var(--container-padding);
  }
  .heading,
  .quantity {
    font-size: var(--lg);
  }
}
