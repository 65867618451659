.container {
  flex: 1 1 0px;
  border: 1px solid #c8c3b6;
  box-shadow: var(--box-shadow-all);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--text-color);
  background-color: var(--background-color);
}
.bundle_banner {
  background-color: var(--heading-background-color);
  color: var(--heading-color);
  font-weight: var(--heading-weight-black);
  padding: var(--container-padding) 0;
  text-align: center;
}
.product_detail_container {
  display: flex;
  flex-direction: column;
}
.product_image_wrapper {
  width: 100%;
}
.price_button_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--container-margin) 0;
}
.product_btn {
  width: 95%;
  margin: 0 auto;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  font-weight: var(--text-weight-black);
  font-size: var(--xl3);
  padding: var(--container-padding) 0;
  cursor: pointer;
}
.product_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.price_wrapper {
  font-weight: var(--text-weight-black);
}
.bundle_name_items_wrapper {
  padding: 0 var(--container-padding);
}
.product_name,
.price_wrapper {
  margin: 0;
  color: var(--product-name-color);
  margin-bottom: var(--container-margin);
}
.compare_price {
  color: var(--compare-at-color);
  text-decoration: line-through;
  font-weight: var(--text-weight-black);
}
@media screen and (max-width: 980px) {
  .product_detail_container {
    flex-direction: row;
    padding-bottom: var(--container-padding);
  }
  .product_image_wrapper {
    flex: 0 0 39%;
  }
  .bundle_detail_wrapper p,
  .bundle_detail_wrapper li {
    padding-left: 0;
    font-size: var(--md);
  }
  .bundle_detail_wrapper ul {
    padding-left: var(--container-padding);
  }
  .bundle_name_items_wrapper {
    padding-top: var(--container-padding);
  }
  .price_button_wrapper {
    margin: 0;
  }
  .product_name {
    font-size: var(--xl2);
  }
  .product_name,
  .price_wrapper,
  .product_btn {
    margin: 0;
    margin-bottom: var(--container-padding);
  }
  .product_btn {
    font-size: var(--xl2);
  }
}
