.main {
  border-radius: var(--border-radius);
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.main:disabled {
  cursor: default;
}
.selected {
  background-color: var(--default-background-color);
  border: 2px solid var(--border-color1);
}
.unselected {
  background-color: var(--background-color-grey);
  border: none;
  color: #9c9b9b;
}
.logo_name {
  display: flex;
  gap: var(--container-padding);
  align-items: center;
}
.logo_name > div {
  border-radius: var(--border-radius);
  width: 38px;
  height: 38px;
  background-color: white;
  border: 1px solid #d8d8d8;
}
.logo_name h5 {
  font-size: var(--lg);
  margin: 0;
  color: var(--heading-color-dark);
}
.text_wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}
.text_wrapper span,
.text_wrapper div {
  display: flex;
  gap: var(--container-padding);
  align-items: center;
}
.tags_wrapper {
  display: flex;
  gap: var(--container-padding);
  justify-content: space-between;
  flex-wrap: wrap;
}
.tags_wrapper span {
  padding: 5px var(--container-padding);
  border-radius: 20px;
  white-space: nowrap;
  border: 2px solid rgb(0, 0, 0, 0.1);
}
.price_wrapper {
  display: flex;
  gap: var(--container-padding);
}
.percentage_off {
  color: var(--color-negative);
}
.price {
  font-weight: var(--text-weight-bold);
}
.compareAtPrice {
  text-decoration: line-through;
}
