.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.heading {
  font-size: var(--md);
  color: var(--heading-color);
}
.text {
  font-size: var(--sm);
  color: var(--text-color);
}
.icon {
  font-size: var(--xl3);
  color: var(--icon-color);
}
