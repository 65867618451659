.container {
  background-color: var(--background-color); 
  max-width: 80vw;
  margin: 0 auto;
}

@media screen and (max-width: 790px) {
  .container {
    max-width: 100%;
  }
}

.navControls {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 24px
}

.blazeNext, .blazePrev {
  color: var(--text-color-grey-dark);
  font-size: 20px;
  height: 20px;
  width: 20px;
  z-index: 100
}

.blazePagination {
  gap: 14px
}

.blazePagination {
  display: flex;
  gap: 14px;
  justify-content: center
}

.blazePagination button {
  margin: 5px 0;
  background-color: var(--text-color-grey-dark);
  border-color: var(--text-color-grey-dark);
  color: var(--text-color-grey-dark);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: 0;
  transition: transform .2s,background .3s
}

.blazePagination :global button.active {
  background-color: var(--text-color-dark);
  border-color: var(--text-color-dark);
  color: var(--text-color-dark);
  transform: scale(1.2)
}
