.container {
  flex: 1 1 0px;
  border: 1px solid var(--border-color);
  box-shadow: var(--box-shadow-all);
  display: flex;
  flex-direction: column;
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 6px;
  overflow: hidden;
  height: fit-content;
  max-width: 550px;
  height: 100%;
}

.details_container {
  display: flex;
  flex-direction: column;
  padding: 0 var(--container-margin);
  height: 100%;
  flex-grow: 1;
}

.bundle_banner {
  background-color: var(--heading-background-color);
  color: var(--heading-color);
  font-weight: var(--heading-weight-black);
  padding: var(--container-padding) 0;
  text-align: center;
  margin-bottom: var(--container-margin);
}
.product_detail_container {
  display: flex;
  flex-direction: column;
}
.product_detail_container p {
  margin-bottom: var(--container-margin);
}
.product_image_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: var(--container-margin);
}

.product_image {
  width: 250px;
  height: 250px !important;
  object-fit: cover;
  border-radius: 6px;
}

.badge_price_wrapper {
  display: flex;
  gap: var(--container-margin);
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--container-margin);
} 

.badge_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  height: 100%;
  gap: 10px;
}

.badge {
  min-width: 50px;
  min-height: 50px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.prices_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  height: 100%;
}

.price_button_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--container-margin) 0;
}
.product_btn {
  width: 95%;
  margin: 0 auto;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  font-weight: var(--text-weight-black);
  font-size: var(--xl2);
  padding: var(--container-padding) 0;
  cursor: pointer;
  border-radius: 6px;
}
.product_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.price_wrapper {
  font-weight: var(--text-weight-black);
}
.bundle_name_wrapper {
  margin-bottom: var(--container-margin);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 54px;
}
.product_name,
.price_wrapper {
  margin: 0;
  color: var(--product-name-color);
}
.compare_price {
  color: var(--compare-at-color);
  text-decoration: line-through;
  font-weight: var(--text-weight-black);
}

.bundle_detail_wrapper {
  margin-bottom: var(--container-margin);
  flex-grow: 1;
}

.select {
  width: 95%;
  margin: 0 auto;
  margin-bottom: var(--container-margin);
  height: 40px;
  color: var(--text-color-dark);
  outline: none;
  font-size: var(--l);
}
.rating_wrapper {
  margin-bottom: var(--container-margin);
}

