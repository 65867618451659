.modal {
  position: fixed;
  z-index: var(--z-index-modal);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal_content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: var(--container-padding);
  border: 1px solid #888;
  width: 80%;
}
.close {
  color: #aaa;
  float: right;
  font-size: var(--xl3);
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: var(--text-color-dark);
  text-decoration: none;
  cursor: pointer;
}
.heading {
  color: var(--heading-color);
  text-align: center;
  font-weight: var(--heading-weight-normal);
  margin-bottom: var(--container-margin);
}
.text {
  color: var(--text-color);
  min-height: 15vw;
  max-height: 30vw;
  overflow: scroll;
  padding: 0 var(--container-padding);
}
.link {
  color: var(--text-color);
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: var(--text-weight-black);
  padding: 0;
  margin-bottom: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .modal_content {
    padding: var(--container-padding);
  }
  .heading {
    font-size: var(--xl4);
  }
  .text {
    max-height: 110vw;
    padding: 0;
  }
}
