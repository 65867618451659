.container {
  background-color: var(--background-color);
}
.reviews_wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    margin: 0 auto;
    width: 100%;
    padding: 0 var(--container-padding);
  }
  .reviews_wrapper {
    gap: var(--container-margin-mobile);
  }
}
