.container {
  padding: var(--container-padding) 10%;
  background-color: var(--background-color);
}
.heading {
  color: var(--heading-color);
  text-align: center;
  margin: 0;
  margin-bottom: var(--container-margin);
}
.sec_text {
  color: var(--text-color);
  margin: 0;
  margin-bottom: var(--container-margin);
  text-align: center;
}
.videos_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--container-margin);
  row-gap: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding) 0;
  }
  .container h1 {
    padding: 0 var(--container-padding);
  }
  .heading {
    font-size: var(--xl4);
  }
  .videos_container {
    display: flex;
    flex-direction: column;
  }
}
