.hero_container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 50%);
  padding: var(--container-padding) 10%;
  margin: 0 auto;
}
.hero_content {
  color: var(--text-color);
  margin-left: var(--container-margin);
}
.hero_content h1 {
  margin-bottom: var(--container-margin);
  font-size: var(--xl4);
  color: var(--heading-color);
}
.hero_content h2 {
  font-weight: var(--heading-weight-normal);
  font-size: var(--xl2);
  color: var(--subheading-color);
  margin-bottom: var(--container-margin);
}
.hero_content ul {
  margin: 0;
}
.hero_content p,
.hero_content ul {
  margin-bottom: var(--container-margin);
}
.hero_reviews {
  display: flex;
  align-items: center;
  margin-bottom: var(--container-margin);
}
.hero_reviews p {
  flex: 0 0 60%;
  padding-left: var(--container-padding);
  margin: 0 !important;
}
.rating_text {
  color: var(--rating-text-color);
}
.hero_logo_container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--container-padding);
  padding-top: var(--container-padding);
}
.hero_logo_container div {
  flex: 0 0 15%;
}
.stars {
  font-size: var(--xl2);
  white-space: nowrap;
}
@media screen and (max-width: 980px) {
  .hero_container {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: var(--container-padding);
    width: 100%;
  }
  .img_wrapper {
    margin-bottom: var(--container-margin-mobile);
  }
  .hero_content {
    padding: 0 var(--container-padding);
    margin: 0;
  }
  .hero_reviews {
    justify-content: space-between;
    margin-bottom: var(--container-margin-mobile);
  }
  .hero_reviews p {
    text-align: right;
    padding: 0;
    font-size: var(--md);
  }
  .hero_logo_container {
    width: 100%;
    margin: 0 auto;
  }
  .stars {
    font-size: var(--lg);
  }
}
