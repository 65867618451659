.container {
  padding: var(--container-padding) 10%;
}
.heading {
  color: var(--heading-color);
  text-align: center;
  margin-bottom: var(--container-margin);
}
.text {
  color: var(--text-color);
  margin-bottom: var(--container-margin);
  text-align: center;
}
.container p {
  margin-bottom: var(--container-margin);
}
@media screen and (max-width: 980px) {
  .container {
    padding: var(--container-padding);
  }
  .container p {
    margin-bottom: var(--container-margin-mobile);
  }
  .heading {
    font-size: var(--xl4);
  }
}
