.container {
  width: 100%;
  display: flex;
  padding: var(--container-padding) 10%;
  justify-content: center;
  gap: var(--container-margin);
}

@media screen and (max-width: 980px) {
  .container {
    flex-direction: column;
    padding: var(--container-padding) 0;
  }
}
