.container {
  flex: 0 0 20%;
}
.label_text_wrapper {
  display: flex;
  justify-content: center;
}
.label {
  color: var(--label-color);
  display: flex;
  align-items: center;
}
.text {
  color: var(--text-color);
  padding-left: var(--container-padding);
}
