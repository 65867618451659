.accept_button {
  width: 70%;
  padding: var(--container-padding);
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
  font-weight: var(--text-weight-black);
  cursor: pointer;
  outline: none;
  font-size: var(--xl4);
  margin: 0 auto;
  margin-bottom: var(--container-margin);
}
.accept_button:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.reject_button {
  color: var(--reject-button-text-color);
  text-decoration: underline;
  cursor: pointer;
  font-weight: var(--text-weight-black);
  background-color: transparent;
  border: none;
  outline: none;
  font-size: var(--xl);
}
.accept_button:disabled,
.reject_button:disabled {
  cursor: default;
  opacity: 0.5;
}
.invalid_msg {
  background-color: #fccccc;
  padding: 20px;
  margin: 10px 0;
  text-align: left;
  color: var(--color-negative);
  border-radius: 10px;
  border: 1px solid var(--color-negative);
}
.invalid_msg::before {
  content: '⨂';
  font-style: normal;
  font-weight: var(--heading-weight-bold);
  font-size: var(--xl);
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.5em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 0.5em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
}

.paypal_wrapper {
  z-index: 0;
  width: 100%;
}

.paypal_wrapper > div {
  display: flex;
  justify-content: center;
}

.paypal_wrapper button:disabled {
  cursor: default;
  opacity: 0.5;
}
@media screen and (max-width: 980px) {
  .accept_button {
    width: 100%;
    font-size: var(--xl3);
  }
  .reject_button {
    font-size: var(--x2);
  }
  .invalid_msg {
    font-size: var(--lg);
  }
}
