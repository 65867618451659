.text {
  color: var(--text-color);
  width: 90%;
}
.text p {
  font-size: var(--md);
}
.img_wrapper {
  width: 16px;
}
.bullet_container {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media screen and (max-width: 980px) {
  .img_wrapper {
    width: 14px;
  }
}
