.container {
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.icon_container {
  position: relative;
  width: 50px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_wrapper {
  position: absolute;
  width: 24px;
  z-index: var(--z-index-overlay);
}
.glow_effect {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  background-image: radial-gradient(
    circle farthest-corner at 50% 50%,
    var(--icon-glow-color) 34%,
    hsla(0, 0%, 100%, 0) 59%
  );
  opacity: 0.55;
  animation: glow 1s alternate-reverse infinite;
}
.text {
  color: var(--text-color);
  font-size: var(--sm);
  margin-left: -5px;
}
.emphasized {
  font-weight: var(--text-weight-bold);
}
@media screen and (max-width: 980px) {
  .icon_wrapper {
    width: 18px;
  }
  .icon_container {
    width: 40px;
    height: 35px;
  }
  .text {
    margin-left: -2px;
  }
}

@keyframes glow {
  0% {
    transform: scale3d(0.5, 0.5, 1);
  }
  25% {
    transform: scale3d(0.6, 0.6, 1);
  }
  50% {
    transform: scale3d(0.7, 0.7, 1);
  }
  75% {
    transform: scale3d(0.8, 0.8, 1);
  }
  100% {
    transform: scale3d(0.9, 0.9, 1);
  }
}
