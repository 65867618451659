.box_item {
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
}
.heading {
  margin: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--heading-color);
  background-color: var(--heading-background-color);
  margin-bottom: var(--container-margin);
}
.box_item video {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .box_item {
    padding-top: var(--container-padding);
  }
  .heading {
    margin-bottom: var(--container-margin-mobile);
  }
  .box_item p {
    margin-bottom: 0;
  }
}
