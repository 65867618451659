.embla {
  position: relative;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.img_carousel_slide {
  height: 550px;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
  height: 100%;
}

.embla__slide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.embla__slide__inner > img {
  width: 100% !important;
  height: 100% !important;
}

.embla__slide__inner img {
  object-fit: cover;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 20px;
}
.embla_thumb {
  padding-top: 0;
  margin-top: var(--container-padding);
  height: 100px;
}

.embla_container_thumb {
  cursor: default;
  display: flex;
  user-select: none;
  height: 100%;
  flex-wrap: wrap;
  gap: var(--container-padding);
}

.hide_dots_on_desktop {
  display: none;
}

.image_count {
  display: none;
}

@media screen and (max-width: 980px) {
  .embla__dots {
    padding-top: 13px;
  }
  .img_carousel_slide {
    height: auto;
  }
  .embla_thumb {
    display: none;
  }
  .hide_dots_on_desktop {
    display: flex;
  }
  .hide_dots_on_mobile {
    display: none;
  }
  .image_count {
    display: block;
    background-color: white;
    border-radius: var(--border-radius);
    position: absolute;
    bottom: var(--container-padding);
    right: var(--container-padding);
    padding: var(--container-padding);
  }
  .embla__slide__inner img {
    object-fit: contain;
    width: 100% !important;
    height: auto !important;
  }
}
