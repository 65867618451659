.container {
  position: relative;
  width: 100%;
  height: 100%;
}
.container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}
.image {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}
.image_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image_wrapper > img {
  position: unset !important;
}
