.main {
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.external_link {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  text-align: center;
  width: 90%;
  margin: 0 auto;
  height: 50px;
  border: 1px solid var(--button-border-color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.external_link a:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border: 1px solid var(--button-hover-border-color);
}
.external_link a {
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
  text-decoration: none !important;
}
.products_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.shipping {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}
.shipping p,
.shipping {
  font-size: var(--md);
  font-weight: var(--text-weight-bold);
}
.content_wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 12px;
}
.price_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price {
  display: flex;
  gap: 8px;
}
.compare_price {
  text-decoration: line-through;
  color: var(--compare-at-color);
}
.btn_wrapper button {
  width: 100%;
  height: 65px;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  cursor: pointer;
  font-size: var(--xl);
  font-weight: var(--text-weight-bold);
  font-family: var(--text-font-family);
  border-radius: 5px;
}
.btn_wrapper button:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border: 1px solid var(--button-hover-border-color);
}
.btn_wrapper button:disabled {
  opacity: 0.5;
  cursor: default;
}
.saving_txt {
  color: var(--color-positive);
  font-weight: var(--text-weight-bold);
}
.total_price {
  font-weight: var(--text-weight-bold);
  font-size: var(--xl);
}
@media screen and (max-width: 980px) {
  .main {
    padding: 14px;
  }
  .callout_container > div {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .shipping,
  .shipping p,
  .saving_txt,
  .compare_price,
  .total_price {
    font-size: var(--sm);
  }
  .btn_wrapper button {
    font-size: var(--md);
  }
  .external_link {
    width: 100%;
  }
}
