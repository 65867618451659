.container {
  padding: var(--container-padding) 0;
  background-color: var(--background-color);
}
.testimonial_section {
  color: var(--text-color);
}
.sec_wrapper {
  margin: 0 auto;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
}
.testimonial_heading {
  text-align: center;
  color: var(--heading-color);
}
.testimonial_text {
  text-align: center;
}
.ratings_wrapper {
  background-color: var(--ratings-background-color);
  box-shadow: var(--box-shadow-all);
  border-radius: 15px;
  padding: var(--container-padding) 30px;
  width: 600px;
  margin: 0 auto;
}
.testimonial_social_wrapper {
  display: flex;
  justify-content: space-between;
  gap: var(--container-padding);
  flex-wrap: wrap;
}
.testimonial_amazon {
  display: flex;
  flex-direction: column;
  gap: var(--container-padding);
}
.social_reviews_header {
  padding: 0 var(--container-padding);
}
.mobile {
  display: none;
}
@media screen and (max-width: 980px) {
  .sec_wrapper {
    width: 100%;
    padding: 0;
  }
  .testimonial_heading {
    padding: 0 var(--container-padding);
    font-size: var(--xl4);
  }
  .testimonial_text {
    padding: 0 var(--container-padding);
  }
  .ratings_wrapper {
    width: 95%;
    padding: var(--container-padding);
  }
  .testimonial_amazon {
    margin: 0;
    gap: var(--container-margin);
  }
  .carousel_item {
    border-radius: 10px;
    box-shadow: none;
    padding: var(--container-padding);
    margin: 0 var(--container-margin-mobile);
    height: 100%;
  }
  .carousel_item p {
    padding: 0;
  }
  .social_reviews_header p {
    margin: 0;
    padding: 0;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .carousel_item_wrapper {
    height: 100%;
  }
}
