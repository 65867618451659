.container {
  display: flex;
  justify-content: space-between;
  padding-top: var(--container-padding);
}
.image_left {
  flex-direction: row;
}
.image_right {
  flex-direction: row-reverse;
}
.image_container {
  flex: 0 0 40%;
  display: flex;
  align-items: center;
}
.image_wrapper {
  flex: 0 0 95%;
}
.detail {
  flex: 0 0 58%;
  height: 100%;
  align-self: center;
}
.heading {
  font-size: var(--xl5);
  font-weight: var(--heading-weight-normal);
  color: var(--heading-color);
  margin-bottom: var(--container-margin);
}
.subheading {
  color: var(--heading-color);
  margin-bottom: var(--container-margin);
}
.text {
  color: var(--text-color);
}
@media screen and (max-width: 980px) {
  .container {
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;
  }
  .heading,
  .subheading {
    text-align: center;
  }
  .heading {
    font-size: var(--xl3);
  }
  .subheading {
    font-size: var(--xl2);
  }
  .image_container {
    margin-bottom: var(--container-margin);
    justify-content: center;
  }
}
