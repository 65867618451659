.container {
  padding: var(--container-padding) 0;
  background-color: var(--background-color);
}
.featured_in {
  display: flex;
  justify-content: center;
  padding: 40px 10%;
  flex-wrap: wrap;
  gap: var(--container-margin);
}
.heading {
  color: var(--heading-color);
  text-align: center;
  margin-bottom: var(--container-margin);
}
.featured_in span {
  padding: 0 !important;
}
.image_wrapper {
  flex: 0 0 18%;
}
.image_wrapper img {
  width: 100% !important;
  height: 100% !important;
  position: static !important;
}
@media screen and (max-width: 980px) {
  .featured_in {
    padding: var(--container-padding);
    justify-content: space-around;
    gap: var(--container-margin-mobile);
  }
  .heading {
    font-size: var(--xl4);
  }
  .image_wrapper {
    flex: 0 0 40%;
  }
}
