.container {
  background-color: var(--background-color);
  padding: var(--container-padding) 0;
}
.value_proposition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  color: var(--text-color);
}
.heading {
  color: var(--heading-color);
  margin-bottom: var(--container-margin);
  text-align: center;
}
.text {
  text-align: center;
}
.sec_wrapper {
  display: flex;
  width: 100%;
  gap: var(--container-margin);
  padding-top: var(--container-padding);
  flex-wrap: wrap;
}
.divider_text {
  background-color: var(--divider-background-color);
  color: var(--divider-text-color);
  padding: var(--container-padding);
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.divider_text h2 {
  padding: var(--container-padding);
}
@media screen and (max-width: 980px) {
  .value_proposition {
    padding: 0 var(--container-padding);
  }
  .value_proposition h1 {
    text-align: center;
    font-size: var(--xl4);
  }
  .sec_wrapper {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 0;
    gap: var(--container-margin-mobile);
  }
  .divider_text {
    width: 60px;
    height: 60px;
    margin-top: var(--container-margin-mobile);
  }
}
