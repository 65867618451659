.container {
  padding: var(--container-padding) 0;
}
.product_info_container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 25px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  background-color: var(--product-info-background-color);
  color: var(--product-info-text-color);
}
.product_info {
  width: 48%;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mini_cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_name {
  flex: 0 0 60%;
  display: flex;
  align-items: center;
}
.price_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_wrapper h4 {
  text-align: right;
}
.cta_button {
  width: 50%;
  height: 76px;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  border-radius: 5px;
  border: 1px solid var(--button-border-color);
  font-weight: var(--text-weight-black);
  cursor: pointer;
  align-self: center;
  justify-self: center;
}
.cta_text {
  font-size: var(--xl4);
}
.cta_button:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
@media screen and (max-width: 980px) {
  .product_info_container {
    width: 100%;
    padding: var(--container-padding);
    padding-bottom: 0;
    flex-direction: column;
  }
  .product_info {
    width: 100%;
    padding: 0;
  }
  .product_info h2 {
    font-size: var(--xl4);
  }
  .mini_cart h2 {
    font-size: var(--xl2);
  }
  .mini_cart h4 {
    font-size: var(--sm);
  }
  .cta_button {
    width: 100%;
    margin: var(--container-margin) auto;
  }
  .cta_text {
    font-size: var(--xl2);
  }
}
