.embla__button {
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  touch-action: manipulation;
  position: absolute;
  z-index: var(--z-index-overlay);
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  fill: var(--button-color);
  box-shadow: 0 0 0 1px var(--black, #000), 0 1px 14px hsla(var(--black-hsl), 0.1);
  padding: 0;
}
.embla__button svg {
  width: 100%;
  height: 100%;
}
.embla__button:disabled {
  display: none;
}
@media screen and (max-width: 980px) {
  .embla__button {
    display: none !important;
  }
}
