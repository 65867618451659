.main {
  border-radius: var(--border-radius);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--container-padding);
  text-align: left;
  cursor: pointer;
}
.container_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
}
.callout {
  height: 30px;
  background-color: var(--callout-background-color);
  color: var(--callout-text-color);
  font-size: var(--lg);
  width: fit-content;
  padding: 5px;
  margin: 0 auto;
  font-weight: var(--text-weight-bold);
  border-radius: 5px 5px 0 0;
}
.saving_percent {
  height: 30px;
  padding: 5px;
  text-align: center;
}
.saving_percent p {
  font-weight: var(--text-weight-bold);
}
.selected {
  background-color: var(--selected-background-color);
  border: 2px solid var(--border-color1);
}
.unselected {
  background-color: var(--unselected-background-color);
  border: none;
}
.main > img {
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 10px !important;
}
.main img {
  object-fit: contain;
}
.product_name {
  margin: 0 0 10px 0;
  width: 100%;
}
.product_price {
  margin: 0;
  width: 100%;
  margin-bottom: 10px;
}
