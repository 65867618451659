.container {
  padding: var(--container-padding) 0;
}
.founders_story {
  color: var(--text-color);
  padding: 0 10%;
}
.heading {
  margin: 0;
  font-weight: var(--heading-weight-black);
  text-align: center;
}
.subheading p {
  margin-bottom: var(--container-margin);
  text-align: center;
}
.founders_title {
  color: var(--heading-color);
  margin: 0 auto;
  margin-bottom: var(--container-margin);
}
.cards_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--container-margin);
  margin: 0 auto;
  padding-top: var(--container-padding);
}
@media screen and (max-width: 980px) {
  .founders_story {
    padding: 0 var(--container-padding);
  }
  .founders_title {
    font-size: var(--xl4) !important;
  }
  .cards_wrapper {
    grid-template-columns: 100%;
    padding-top: 0;
  }
}
