.embla_slide {
  min-width: 100%;
  position: relative;
}
.embla_slide_thumb {
  min-width: 100px;
  max-height: 100px;
}
.embla_is_selected .embla_slide_thumbnail {
  opacity: 1;
}
.embla_thumb_btn {
  cursor: pointer;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.embla_thumb_btn > img {
  display: block !important;
}
.embla_thumb_btn img {
  object-fit: cover;
}
.embla_slide_thumbnail {
  position: absolute;
  opacity: 0.2;
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;

  max-width: none;
}
.video_icon {
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  z-index: var(--z-index-overlay);
  font-size: var(--lg);
}
