.open {
  max-height: 100%;
}
.closed {
  max-height: var(--xl7);
}
.container {
  margin: 0 auto;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 20px;
  border: 1px solid var(--border-color1);
  padding: 0 var(--container-padding);
  overflow: hidden;
  width: 100%;
}
.container button {
  width: 100%;
  height: var(--xl7);
  padding: 0 !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.container p {
  margin-bottom: var(--container-margin);
  padding-left: var(--container-padding);
}
.heading {
  margin: 0;
  display: flex;
  text-align: left;
  padding-left: var(--container-padding);
}
.container header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container header h1 {
  font-size: var(--xl2);
  font-weight: var(--text-weight-black);
}
.contents h1 {
  font-size: var(--xl);
}
.plus_minus_p {
  padding: 0 !important;
  margin: 0 !important;
}
@media screen and (max-width: 980px) {
  .container {
    width: 100%;
    padding: 0 var(--container-padding);
  }
  .container h1 {
    font-size: var(--xl) !important;
  }
  .container p {
    padding-right: 0;
  }
}
