.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: var(--xl7);
  color: var(--icon-color);
}

.text {
  max-width: 70%;
}

.text p,
.text span {
  color: var(--text-color);
}

@media screen and (max-width: 980px) {
  .icon {
    font-size: var(--xl6);
  }
  .text {
    max-width: 60%;
  }
}
