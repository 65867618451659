.embla {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.embla__viewport {
  display: flex;
  width: 100%;
  mask-image: linear-gradient(90deg, transparent, #000 1px, #000 calc(100% - 1.5rem), transparent);
  padding-right: 20px;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  gap: var(--container-padding);
}
.embla__slide {
  position: relative;
  flex: 0 0 150px;
  object-fit: fill;
}
.embla__slide__inner {
  height: 100%;
}

@media screen and (max-width: 980px) {
  .embla__viewport {
    margin-left: 0 -5px;
    padding: 0 var(--container-padding);
  }
}
